<template>
  <div class="__main_card_container">
    <div class="_main_card_header">
      <div class="__header_inner admin_header_padding">
        <div
          style="min-width: fit-content; padding-right: 10px"
          class="desktop-nav-sender">
          <button
            class="btn"
            :class="[
              $route.path.includes('sender_ids') ? '__side_item_active' : '',
            ]"
            @click="
              $router.replace('/admin/sender_ids/pending').catch((err) => {})
            ">
            Sender IDs
          </button>
          <button
            v-if="!disallowedUsers"
            class="btn"
            :class="[
              $route.path == '/admin/sender_ids/PendingReseller'
                ? '__side_item_active'
                : '',
            ]"
            @click="
              $router
                .replace('/admin/sender_ids/PendingReseller')
                .catch((err) => {})
            ">
            Reseller Sender IDs
          </button>
        </div>

        <div
          class="side_nav_wrapper"
          style="overflow-x: auto; min-height: 3rem">
          <div class="__side_nav">
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/pending'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/sender_ids/pending').catch((err) => {})
              ">
              Pending {{ `(${pending ? pending : 0})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/approved'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/sender_ids/approved').catch((err) => {})
              ">
              Approved {{ `(${approved ? approved : 0})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/declined'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router.replace('/admin/sender_ids/declined').catch((err) => {})
              ">
              Declined {{ `(${declined ? declined : 0})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/pendingUserVerification'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/pendingUserVerification')
                  .catch((err) => {})
              ">
              Pending user verification
              {{ `(${pendingUserApproval ? pendingUserApproval : 0})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/autoApproved'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/autoApproved')
                  .catch((err) => {})
              ">
              Auto Approved {{ `(${autoApproved ? autoApproved : 0})` }}
            </button>
            <button
              class="btn"
              :class="[
                $route.path == '/admin/sender_ids/ReservedSenderIDs'
                  ? '__side_item_active'
                  : '',
              ]"
              @click="
                $router
                  .replace('/admin/sender_ids/ReservedSenderIDs')
                  .catch((err) => {})
              ">
              Reserved Sender IDs {{ `(${reserved ? reserved : 0})` }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="main_card_body">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  import store from "@/state/store.js";
  export default {
    components: {},
    data() {
      return {
        approvedCount: 0,
        pendingCount: 0,
        declinedCount: 0,
        pendingUserApprovalCount: 0,
        autoApprovedCount: 0,
        ReservedSenderIDs: 0,
      };
    },
    computed: {
      getPageTitle() {
        const currentPage = this.$route.path;
        if (currentPage == "/admin/sender_ids/pending") {
          return "Sender ID Requests";
        }
        if (currentPage == "/admin/sender_ids/approved") {
          return "Approved Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/declined") {
          return "Declined Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/pendingUserVerification") {
          return "Holding Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/autoApproved") {
          return "Auto Approved Sender IDs";
        }
        if (currentPage == "/admin/sender_ids/ReservedSenderIDs") {
          return "Reserved Sender IDs";
        }
      },
      pending() {
        return store.getters["senderid/pendingSenderIDs"];
      },
      approved() {
        return store.getters["senderid/approvedSenderIDs"];
      },
      autoApproved() {
        return store.getters["senderid/autoApprovedSenderIDs"];
      },
      pendingUserApproval() {
        return store.getters["senderid/holdingSenderIDs"];
      },
      declined() {
        return store.getters["senderid/declinedSenderIDS"];
      },
      reserved() {
        return store.getters["senderid/reservedSenderIds"];
      },
      disallowedUsers() {
        return this.$store.getters["auth/disallowedUsers"];
      },
    },
    mounted() {
      store
        .dispatch("senderid/adminSenderIDs", { status: "Approved", page: 1 })
        .then((res) => {})
        .catch((err) => {});
    },
  };
</script>

<style scoped>
  .btn {
    width: max-content;
  }
  @media screen and (max-width: 1025px) {
    .desktop-nav-sender {
      display: none;
    }

    button {
      width: max-content;
    }
  }
</style>
